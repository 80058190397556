import { Container, Row, Col, Badge, Button, Spinner, Modal} from 'react-bootstrap'
import { useState, useEffect, useContext, useLayoutEffect } from 'react'
import { useNavigate, redirect } from "react-router-dom"
import supabase from '../../config/supabaseClient'
import { UserContext } from '../../App2'
import DocImageModal from './DocImageModal'
import moment from 'moment/moment'
import DocEditModal from './docs/DocEditModal'
import CreateDocModal from './docs/CreateDocModal'
import { set } from 'react-hook-form'
const FilterableTable = require('react-filterable-table');
const JSZip = require('jszip');

const ViewDocsAlt = (props) => {
    const { profile, session } = useContext(UserContext)
    const navigate = useNavigate()
    const [res, setRes] = useState(null)
    const [showImageModal, setShowImageModal] = useState(false)
    const [image_urls, setImageUrls] = useState(null)
    const [showEditModal, setShowEditModal] = useState(false)
    const [doc, setDoc] = useState(null)
    const [docTypes, setDocTypes] = useState([])
    const [fields, setFields] = useState(null)
    const [loading, setLoading] = useState(true)
    const [downloadProgress, setDownloadProgress] = useState(0)
    const [downloadModal, setDownloadModal] = useState(false)

    const handleCloseDownloadModal = () => setDownloadModal(false)
    const handleShowDownloadModal = () => setDownloadModal(true)
    const downloadAllFilesFromBucket = async () => {
        setDownloadProgress(0);
        handleShowDownloadModal();
        const mimeTypes = {
            "application/pdf": "pdf",
            "image/jpeg": "jpg",
            "image/png": "png",
            "application/msword": "doc",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
            "application/vnd.ms-excel": "xls",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
            "text/plain": "txt",
            "application/zip": "zip",
            "application/x-rar-compressed": "rar",
            "application/x-7z-compressed": "7z",
            "application/json": "json",
            "text/csv": "csv"
        };
        try {
            const allUsers = await (await supabase.from('user_profiles').select('*')).data;
            console.log(allUsers);
            const bucket = "documents";
            // Get a list of all folders (user IDs) in the bucket
            const { data: folders, error: foldersError } = await supabase.storage
                .from(bucket)
                .list("", { limit: 1000, offset: 0 });

            if (foldersError) {
                throw foldersError;
            }

            if (!folders || !folders.length) {
                throw new Error("No folders found in the bucket.");
            }

            // Loop through each folder (user ID)
            for (const [index, folder] of folders.entries()) {
                console.log("Downloading files for folder:", folder);
                let folderName = folder.name;
                const zip = new JSZip();

                // Get a list of files in the folder
                const { data: files, error } = await supabase.storage
                    .from(bucket)
                    .list(folderName);

                if (error) {
                    console.error(`Error listing files for folder: ${folderName}`, error);
                    continue;
                }
                if (files && files.length) {
                    // Download each file
                    const filePromises = files.map((file) =>
                        supabase.storage.from(bucket).download(`${folderName}/${file.name}`)
                    );

                    const responses = await Promise.allSettled(filePromises);

                    // Add each file to the zip
                    responses.forEach((result, index) => {
                        if (result.status === "fulfilled") {
                            const mimeType = files[index].metadata.mimetype;
                            const fileExtension = mimeTypes[mimeType] || 'bin';
                            const user = allUsers.find(user => user.user_id === folderName);
                            if (user) {
                                folderName = user.fname + '-' + user.lname + '-' + user.user_simple_id;
                            }
                            const fileName = `${folderName}/${files[index].name}.${fileExtension}`;
                            zip.file(fileName, result.value.data);
                        } else {
                            console.error(`Failed to download file: ${files[index].name}`, result.reason);
                        }
                    });

                    // Generate the zip file and create a download link for each folder
                    const zipBlob = await zip.generateAsync({ type: "blob" });
                    const url = URL.createObjectURL(zipBlob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${folderName}.zip`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
                setDownloadProgress(Math.round(((index + 1) / folders.length) * 100));
            }
            handleCloseDownloadModal();
        } catch (error) {
            console.error("Error downloading files from bucket:", error);
        }
    };

    const renderModalButton = (props) => {
        return (
            <div>
                <Button variant="primary" onClick={() => { setShowEditModal(true); setDoc(props.record) }}>
                    Edit
                </Button>
                <Button variant='primary' className='mx-2' onClick={() => navigate('/admin/drivers/' + props.record.user_id, { state: { view: 'docs' } })}>
                    View all driver documents
                </Button>
            </div>

        )
    }
    const renderExpiry = (doc) => {
        let reminder_days = docTypes.find((docType) => docType.id === doc.doc_type).reminder_days
        let startDate = moment(doc.expiry)
        let reminderdays = moment(reminder_days)
        let endDate = moment()
        let duration = moment.duration(startDate.diff(endDate))
        let days = duration.asDays()
        let reminder = moment.duration(reminderdays.diff(endDate))
        let reminderdaysleft = reminder.asDays()
        if (!doc.expiry) {
            return (
                <div>
                    No expiry date
                </div>
            )
        }
        if (days < 0) {
            return (
                <div className='bg-danger text-light'>
                    {doc.expiry ? doc.expiry.split('-').reverse().join('-') : null}
                </div>
            )
        } else if (days < reminderdays) {
            return (
                <div className='bg-warning'>
                    {doc.expiry ? doc.expiry.split('-').reverse().join('-') : null}
                </div>
            )
        } else {
            return (
                <div>
                    {doc.expiry ? doc.expiry.split('-').reverse().join('-') : null}
                </div>
            )
        }
    }

    const renderName = (props) => {
        return (
            <div onClick={() => navigate('/admin/drivers/' + props.record.user_id, { state: { view: 'docs' } })}>
                {props.record.fname} {props.record.lname}
            </div>
        )
    }
    const renderID = (props) => {
        return (
            <div onClick={() => navigate('/admin/drivers/' + props.record.user_id, { state: { view: 'docs' } })}>
                {props.record.user_simple_id}
            </div>
        )
    }
    const renderDate = (props) => {
        let docs = props.record.documents
        let doc = docs.find(doc => doc.doc_type === props.field.name)
        let required = docTypes.find((docType) => docType.id === props.field.name).required
        if (doc) {
            return (
                <div onClick={() => { setShowEditModal(true); setDoc(doc) }}>
                    {renderExpiry(doc)}
                </div>
            )
        } else if (required) {
            return (
                <Badge bg='danger' text='light' className='opacity-50'>
                    Missing
                </Badge>
            )
        } else {
            return (
                <Badge bg='warning' text='dark' className='opacity-50'>
                    Missing
                </Badge>
            )
        }
    }
    const createFields = () => {
        let fields = []
        fields.push({ name: 'user_simple_id', displayName: "Driver ID", inputFilterable: true, exactFilterable: false, sortable: true, render: renderID })
        fields.push({ name: 'fname', displayName: "Driver Name", inputFilterable: true, exactFilterable: false, sortable: true, render: renderName })
        fields.push({ name: 'reg', displayName: "Vehicle Reg", inputFilterable: true, exactFilterable: false, sortable: true })
        docTypes.map((type) => {
            fields.push({ name: type.id, displayName: type.doc_name, inputFilterable: true, exactFilterable: false, sortable: true, render: renderDate })
        })
        return fields
    }
    const fetchDocs = () => {
        setLoading(true)
        supabase
            .from('document_types')
            .select('*')
            .order('order', { ascending: true })
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                }
                else {
                    setDocTypes(data)
                }
            })

        supabase
            .from('user_profiles')
            .select('*, documents(*), vehicle(reg)')
            .filter('active', 'eq', true)
            .filter('isadmin', 'eq', false)
            // .filter('documents:active', 'eq', true)
            .filter('documents.active', 'eq', true)
            .then(({ data, error }) => {
                if (error) {
                    console.log(error)
                }
                else {
                    setRes(data)
                    data.map((user) => {
                        // take vehicle reg from user_profiles 
                        let vehicle_reg = user.vehicle.reg
                        // place vehicle reg in new field in user_profiles called reg
                        user.reg = vehicle_reg
                    })
                    setTimeout(() => {
                        setLoading(false)
                    } , 500)
                }
            })
    }

    useLayoutEffect(() => {
        const fetchDocs = async () => {
            //get list of doc_types 
            await supabase
                .from('document_types')
                .select('*')
                .order('order', { ascending: true })
                .then(({ data, error }) => {
                    if (error) {
                        console.log(error)
                    }
                    else {
                        setDocTypes(data)
                        
                    }
                })

            await supabase
                .from('user_profiles')
                .select('*, documents(*), vehicle(reg)')
                .filter('active', 'eq', true)
                .filter('isadmin', 'eq', false)
                // .filter('documents:active', 'eq', true)
                .filter('documents.active', 'eq', true)
                .then(({ data, error }) => {
                    if (error) {
                        console.log(error)
                    }
                    else {
                        setRes(data)
                        
                        data.map((user) => {
                            // take vehicle reg from user_profiles 
                            let vehicle_reg = user.vehicle.reg
                            // place vehicle reg in new field in user_profiles called reg
                            user.reg = vehicle_reg
                        })
                        setTimeout(() => {
                            setLoading(false)
                        } , 500)
                    }
                })
        }
        fetchDocs()
        setFields(createFields())
    }, [])
    return (
        <div >
            <Modal show={downloadModal} onHide={handleCloseDownloadModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Downloading Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Downloading files from the bucket...</p>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{ width: `${downloadProgress}%` }} aria-valuenow={downloadProgress} aria-valuemin="0" aria-valuemax="100">{downloadProgress}%</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDownloadModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Row className='p-2 py-3'>
                <Col sm={7}>
                    <h1 className='text-start'>Documents</h1>
                </Col>
                <Col sm={5} className='text-end'>
                    <Button variant="info" className='m-2' onClick={() => { fetchDocs(); setLoading(true) }}>
                        {loading ? <Spinner animation="border" variant="dark" size='sm' /> :
                            <>Refresh <i className="fa fa-refresh" aria-hidden="true" ></i></>}
                    </Button>
                    {/* <CreateDocModal /> */}
                    {showEditModal ? <DocEditModal item={doc} show={showEditModal} onHide={() => { setShowEditModal(false); setDoc(null) }} /> : null}
                    <Button variant="primary" className='m-2' onClick={() => navigate('/admin/doctypes')} >Edit Document Types</Button>
                    <Button variant="primary" className='m-2' onClick={() => downloadAllFilesFromBucket()} >Download All Files</Button>
                </Col>
            </Row>
                    
            <FilterableTable
                namespace="Documents"
                initialSort="user_simple_id"
                initialSortDir={true}
                data={res ? res : []}
                fields={createFields()}
                pageSize={200}
                pageSizes={[5, 10, 20, 50, 100, 200]}
                noRecordsMessage="There are no documents to display"
                noFilteredRecordsMessage="No documents match your filters!"
                tableClassName="table table-sm table-striped table-bordered table-hover table-responsive shadow position-absolute start-0 text-center"
            /> 
        </div>
    )
}

export default ViewDocsAlt
